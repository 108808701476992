/**
** JAVASCRIPT
** Name: Navigation
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  var $win         = $(window),
      $body        = $('body'),
      $header      = $('.js-header'),
      $navigation  = $('.js-navigation'),
      $menu        = $('.js-navigation-menu'),
      $items       = $('.js-navigation-item'),
      $link        = $('.js-navigation-link'),
      $toggle      = $('.js-navigation-toggle'),
      $circle      = $('.js-navigation-circle'),
      $sublink     = $('.js-navigation-sublink');

  var $cursor      = $('.js-navigation-cursor'),
      pointer      = { x: 0, y: 0 },
      temp         = { x: 0, y: 0 },
      speedFactor  = 8,
      scrollTop    = 0;

  window.menuActive = false;


  /**
  ** Link on click
  ****************************************/

  $toggle.on('click', function(event) {
    event.preventDefault();

    // Variables

    var $this = $(this),
        $item = $this.parents('.js-navigation-item');


    // Before

    if($('.js-navigation-item.is-active').length < 1 && window.menuActive == false) {
      scrollTop = $win.scrollTop();
      window.menuActive = true;
    }


    // Set classes

    $items.not($item).removeClass('is-active');
    $item.toggleClass('is-active');


    // After
    function openSubmenu() {
      $body.addClass('menu-active');
      $body.css('top', (-scrollTop) + "px");
      $navigation.addClass('is-active');
      $this.attr("aria-expanded", "true");
      var $menu = $navigation.find("[id='" + $this.attr("aria-controls") + "']");
      if ($menu.length > 0) {
        $menu.attr("aria-hidden", "false");
        var $sublinks = $menu.find(".js-navigation-sublink");
        $sublinks.removeAttr("tabindex");
      }
    }
    function closeSubmenu() {
      $body.css('top', '');
      $body.removeClass('menu-active');
      $win.scrollTop(parseInt(scrollTop || '0'));
      $navigation.removeClass('is-active');
      $items.removeClass('is-active');
      $this.attr("aria-expanded", "false");
      var $menu = $navigation.find("[id='" + $this.attr("aria-controls") + "']");
      if ($menu.length > 0) {
        $menu.attr("aria-hidden", "true");
        var $sublinks = $menu.find(".js-navigation-sublink");
        $sublinks.attr("tabindex", "-1");
      }
      window.menuActive = false;
    }

    $(document).keydown(function (e) {
      if (e.key === "Escape" && $body.hasClass('menu-active')) {
        closeSubmenu()
      }
    });

    var submenuTimeout;

    $menu.on('focusin', '.js-navigation-sublink', function () {
      clearTimeout(submenuTimeout);
    });

    $menu.on('focusout', '.js-navigation-sublink', function () {
      submenuTimeout = setTimeout(function () {
        var $sublinks = $menu.find(".js-navigation-sublink");
        var noSublinkFocused = $sublinks.filter(':focus').length === 0;

        if (noSublinkFocused) {
          closeSubmenu();
        }
      }, 100);
    });

    if ($('.js-navigation-item.is-active').length > 0) {
      openSubmenu();
    } else {
      closeSubmenu();
    }
  });

  // Transition


  /**
  ** TOUCH EVENTS
  ************************************************************/

  if(!Modernizr.touchevents) {


    /**
    ** Sublink on hover
    ****************************************/

    $sublink.hover(function() {
      var $this = $(this),
          $target = $($this.data('target'));

      $target.addClass('is-active');
    }, function() {
      var $this = $(this),
          $target = $($this.data('target'));

      $target.removeClass('is-active');
    });


    /**
    ** Cursor move (cursor)
    ****************************************/

    $win.on('mousemove', function(event) {
      if(window.menuActive) {
        pointer = { x: event.pageX, y: event.pageY };
      }
    });

    var loop = function() {
      if(temp != pointer) {
        temp.x = temp.x + (pointer.x - temp.x) / speedFactor;
        temp.y = temp.y + (pointer.y - temp.y) / speedFactor;

        $cursor.css('transform', 'translate3d(' + (temp.x) + 'px' + ', ' + (temp.y) + 'px' + ', 0)');
      }

      window.requestAnimationFrame(loop);
    };

    window.requestAnimationFrame(loop);


    /**
    ** Cursor move (circle)
    ****************************************/

    $link.each(function() {
      var $this       = $(this),
          $circle     = $this.find('.js-navigation-circle'),
          width       = $this.outerWidth(),
          center      = width / 2,
          linkPointer = { x: 0 },
          linkTemp    = { x: 0 };

      $this.on('mousemove', function(event) {
        linkPointer = { x: (event.pageX - $(event.currentTarget).offset().left) };
      });

      var loopCircle = function() {
        if(linkTemp.x != linkPointer.x) {
          linkTemp.x = linkTemp.x + (linkPointer.x - linkTemp.x) / speedFactor;

          $circle.css('transform', 'translate3d(' + ((linkTemp.x - center) / 2) + 'px, 0, 0)');
        }

        window.requestAnimationFrame(loopCircle);
      };

      window.requestAnimationFrame(loopCircle);

    });

  }

})();
