/**
** JAVASCRIPT
** Name: Sticky
********************************************************************************/

(function() {
  'use strict';

  if($('.js-sticky').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $element = $('.js-sticky');
    const $tracked = $($element.data('sticky-to'));
    const $window = $(window);

    const minOffset = 0;
    let maxOffset;
    let stickyOffset;
    let trackedOffset;
    let rafID;
    let idle = true;
    let isEnable = false;

    var isSticky = function(){
      return (
        (['sticky', '-webkit-sticky'].indexOf($element.css('position')) !== -1 || window.getComputedStyle($element.get(0), ':before').getPropertyValue('content') === '"sticky"')
        &&  $tracked.outerHeight() > $element.outerHeight()
        ? true : false
      );
    }


    /**
    ** Init
    ****************************************/

    $window.on('load', function() {
      updateVariables();
    });

    if(isSticky()) {
      updateVariables();
      attach();
      requestStick();
    }


    /**
    ** Update
    ****************************************/

    $window.on('resize', function(){
      if(isSticky()){
        updateVariables();
        if(!isEnable) attach();
      } else if(isEnable) {
        detach();
      }
    });


    /**
    ** Functions
    ****************************************/

    function updateVariables(){
      maxOffset = Math.round($tracked.outerHeight() - $element.outerHeight());
      stickyOffset = parseInt($element.css('top'));
      trackedOffset = $tracked.offset().top;
      requestStick();
    }

    function attach(){
      $window.on('scroll', requestStick);
      isEnable = true;
      idle = true;
    }

    function detach(){
      if(rafID){
        cancelAnimationFrame(rafID);
        rafID = null;
      }
      $window.off('scroll', requestStick);
      $element.css({
        'transform': '',
        'position': '',
      });
      isEnable = false;
    }

    function requestStick(){
      if(idle){
        idle = false;
        rafID = requestAnimationFrame(stick);
      }
    }

    function stick(){
      const currentOffset = $window.scrollTop() - trackedOffset + stickyOffset;
      const offset = Math.round(Math.min(Math.max(currentOffset, minOffset), maxOffset));

      if(offset === minOffset || offset === maxOffset) {
        $element.css({
          'transform': 'translate3d(0, ' + offset + 'px, 0)',
          'position': '',
          'margin-top': '-' + stickyOffset + 'px'
        });
      }
      else {
        $element.css({
          'transform': '',
          'position': 'fixed',
          'margin-top': ''
        })
      }

      idle = true;
    }

  }
})();
