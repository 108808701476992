/**
** JAVASCRIPT
** Name: Card
********************************************************************************/

(function() {
  'use strict';


  /**
  ** TOUCH EVENTS
  ************************************************************/


  if($('.js-card').length > 0 && !Modernizr.touchevents) {


    /**
    ** Variables
    ****************************************/

    var $win        = $(window),
        $card       = $('.js-card'),
        speedFactor = 8;


    $win.on('load', function() {


      /**
      ** Cursor move
      ****************************************/

      $card.each(function() {
        var $this   = $(this),
            $head   = $this.find('.js-card-head'),
            $cursor = $this.find('.js-card-cursor'),
            $circle = $this.find('.js-card-circle'),
            width   = $head.outerWidth(),
            height  = $head.outerHeight(),
            center  = { x: width / 2, y: height / 2 },
            pointer = { x: 0, y: 0 },
            temp    = { x: 0, y: 0 },
            offset  = { x: 0, y: 0 },
            tempOffset = { x: 0, y: 0 };

        $this.on('mousemove', function(event) {
          pointer = { x: (event.pageX - $(event.currentTarget).offset().left),
                      y: (event.pageY - $(event.currentTarget).offset().top) };
          offset  = { x: (Math.abs(pointer.x - center.x) / width),
                      y: (Math.abs(pointer.y - center.y) / height) };
        });

        var loop = function() {
          if(temp != pointer) {
            temp.x = temp.x + (pointer.x - temp.x) / speedFactor;
            temp.y = temp.y + (pointer.y - temp.y) / speedFactor;
            tempOffset.x = tempOffset.x + (offset.x - tempOffset.x) / speedFactor;
            tempOffset.y = tempOffset.y + (offset.y - tempOffset.y) / speedFactor;

            $cursor.css('transform', 'translate3d(' + (temp.x) + 'px' + ', ' + (temp.y) + 'px' + ', 0)');
            $circle.css('transform', 'scale(' + (0.4 + (tempOffset.x + tempOffset.y) * 0.6) + ')');
          }

          window.requestAnimationFrame(loop);
        };

        window.requestAnimationFrame(loop);

      });

    });

  }

})();
