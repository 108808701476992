/**
** JAVASCRIPT
** Name: Slowmotion
********************************************************************************/

(function() {
  'use strict';


  /**
  ** TOUCH EVENTS
  ************************************************************/

  if(!Modernizr.touchevents) {


    /**
    ** Variables
    ****************************************/

    var $win        = $(window),
        windowWidth = $win.outerWidth(),
        $slowmotion = $('.js-slowmotion');


    /**
    ** Animation
    ****************************************/

    $slowmotion.each(function() {


      /**
      ** Variables
      ****************************************/

      var $this   = $(this),
          speed   = $this.data('speed') ? $this.data('speed') : 5,
          factor  = $this.data('factor') ? $this.data('factor') : 40,
          pointer = { x: 0, y: 0 },
          temp    = { x: 0, y: 0 },
          center  = { x: $this.offset().left + $this.outerWidth() / 2, 
                      y: $this.offset().top + $this.outerHeight() / 2 };


      /**
      ** Mousemove
      ****************************************/

      $win.on('mousemove', function(event) {
        if($this.isInViewport()) {
          pointer = { x: event.pageX - center.x, y: event.pageY - center.y };
        }
      });


      /**
      ** Loop
      ****************************************/

      var loop = function() {
        if(temp != pointer) {
          temp.x = temp.x + (pointer.x - temp.x) / speed;
          temp.y = temp.y + (pointer.y - temp.y) / speed;

          $this.css('transform', 'translate3d(' + (-temp.x / factor) + 'px' + ', ' + (-temp.y / factor) + 'px' + ', 0)');
        }

        window.requestAnimationFrame(loop);
      };


      /**
      ** Request
      ****************************************/

      window.requestAnimationFrame(loop);

    });

  }

})();
