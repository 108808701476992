/**
** JAVASCRIPT
** Name: Showcase
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  var $win      = $(window),
      $showcase = $('.js-showcase'),
      $button   = $('.js-showcase-button');


  /**
  ** FUNCTION
  ** Animate introduction
  ****************************************/

  function startShowcase() {
    $showcase.addClass('animate-in');

    setTimeout(function() {
      $showcase.removeClass('animate-in').addClass('animate-out');
    }, 1500);
  }


  /**
  ** Init
  ****************************************/

  $win.on('load', function() {
    startShowcase();
  });


  /**
  ** Click
  ****************************************/

  $button.on('click', function() {
    $showcase.toggleClass('is-reversed');
  });


})();
