/**
** JAVASCRIPT
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  var $win          = $(window),
      $doc          = $(document),
      $body         = $('body'),
      $header       = $('.js-header'),
      $menu         = $('.js-header-menu'),
      $navigation   = $('.js-navigation'),
      $navItem      = $('.js-navigation-item'),
      $navLink      = $('.js-navigation-link'),
      $navToggle    = $('.js-navigation-toggle'),
      scrollTop     = $win.scrollTop(),
      lastScrollTop = 0,
      delta         = 5,
      isMobile      = false,
      didScroll;

  window.navActive = false;


  /**
  ** Function: setPosition
  ****************************************/

  function setPosition() {
    scrollTop = $win.scrollTop();

    if(scrollTop > delta) {
      $header.addClass('is-scrolled');
    }
    else {
      $header.removeClass('is-scrolled');
    }

    if(Math.abs(lastScrollTop - scrollTop) <= delta) {
      return;
    }

    if(scrollTop > lastScrollTop && scrollTop > delta) {
      $header.addClass('is-hidden');
    }
    else if(scrollTop + $win.height() < $doc.height()) {
      $header.removeClass('is-hidden');
    }

    lastScrollTop = scrollTop;
  }


  /**
  ** Function: initTabindex
  ****************************************/

  function initNavTabindex() {
    if(window.outerWidth < 960) {
      $navigation.attr("aria-hidden", "true");
      $navLink.attr("tabindex", "-1");
    }
  }


  /**
  ** Init
  ****************************************/

  $doc.ready(function() {
    setPosition();
    initNavTabindex();

    if(window.outerWidth < 960) {
      isMobile = true;
    }
  });


  /**
  ** Resize
  ****************************************/

  $win.on('resize', function() {
    setPosition();

    if(isMobile && window.outerWidth >= 960) {
      $body.css('top', '');
      $body.removeClass('nav-active menu-active');
      $win.scrollTop(parseInt(scrollTop || '0'));
      $navigation.removeClass('is-opened is-active');
      $navItem.removeClass('is-active');
      window.navActive = false;
      window.menuActive = false;

      $navigation.removeAttr("aria-hidden");
      $navLink.removeAttr("tabindex");

      isMobile = false;
    }
    else if(!isMobile && window.outerWidth < 960) {
      initNavTabindex();

      isMobile = true;
    }
  });


  /**
  ** Action
  ****************************************/

  $win.on('scroll', function() {
    if(!window.navActive) {
      didScroll = true;
    }
  });

  setInterval(function() {
    if(didScroll) {
      setPosition();
      didScroll = false;
    }
  }, 100);


  /**
  ** Menu
  ****************************************/

  $menu.on('click', function(event) {
    event.preventDefault();


    // Before

    if(window.navActive == false) {
      scrollTop = $win.scrollTop();
      window.navActive = true;

      $body.addClass('nav-active');
      $body.css('top', (-scrollTop) + "px");
      $navigation.addClass('is-opened');

      $menu.attr("aria-expanded", "true");
      $navigation.attr("aria-hidden", "false");
      $navLink.removeAttr("tabindex");
    }
    else {
      $body.css('top', '');
      $body.removeClass('nav-active menu-active');
      $win.scrollTop(parseInt(scrollTop || '0'));
      $navigation.removeClass('is-opened is-active');
      $navItem.removeClass('is-active');
      window.navActive = false;
      window.menuActive = false;

      $menu.attr("aria-expanded", "false");
      $navigation.attr("aria-hidden", "true");
      $navToggle.attr("aria-expanded", "false");
      $navLink.attr("tabindex", "-1");
    }

  });


})();
