/**
** JAVASCRIPT
** Name: Portrait
********************************************************************************/

(function() {
  'use strict';


  /**
  ** TOUCH EVENTS
  ************************************************************/

  if(!Modernizr.touchevents) {


    if($('.js-portrait').length > 0) {


      /**
      ** Variables
      ****************************************/

      var $win          = $(window),
          $portrait     = $('.js-portrait'),
          relSize       = { width: 0, height: 0 },
          rel           = { x: 0, y: 0 },
          relTmp        = { x: 0, y: 0 },
          speed         = 20;


      /**
      ** Hover
      ****************************************/

      $portrait.hover(function() {
        var $this = $(this);

        relSize = { width: $this.outerWidth(), height: $this.outerHeight() };

        $this.addClass('is-hovered');
      }, function() {
        var $this = $(this);

        relSize = { width: 0, height: 0 };

        $this.removeClass('is-hovered');
      });


      /**
      ** Mousemove
      ****************************************/

      $portrait.on('mousemove', function(event) {
        rel = { x: event.pageX - $(event.target).offset().left, 
                y: event.pageY - $(event.target).offset().top };
      });


      /**
      ** Loop
      ****************************************/

      var loop = function() {

        if(relTmp.x != rel.x) {
          relTmp.x = relTmp.x + (rel.x - relTmp.x) / (speed / 2);
          relTmp.y = relTmp.y + (rel.y - relTmp.y) / (speed / 2);
          $('.js-portrait.is-hovered .js-portrait-picture').css('clip-path', 'circle(20% at ' + (relTmp.x / relSize.width * 100) + '% ' + (relTmp.y / relSize.height * 100) + '%)');
        }

        window.requestAnimationFrame(loop);
      };


      /**
      ** Request
      ****************************************/

      window.requestAnimationFrame(loop);

    }

  }

})();
