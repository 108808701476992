/**
** JAVASCRIPT
** Name: Profiler
********************************************************************************/

(function() {
  'use strict';


  if($('.js-profiler').length > 0) {


    /**
    ** Global variables
    ****************************************/

    var $win       = $(window),
        $profiler  = $('.js-profiler'),
        $section   = $('.js-profiler-section'),
        $container = $('.js-profiler-container'),
        $items     = $('.js-profiler-items'),
        $item      = $('.js-profiler-item'),
        $prev      = $('.js-profiler-prev'),
        $next      = $('.js-profiler-next'),
        total      = $item.length,
        itemSize   = { width: 0, height: 0 },
        itemsWidth = 0,
        lineBreak  = 0;


    /**
    ** Global Init
    ****************************************/

    $win.on('load', function() {
      lineBreak  = Math.round(total / 2);
      itemSize   = { width: $item.first().outerWidth(),
                     height: $item.first().outerHeight() };
      itemsWidth = lineBreak * itemSize.width;

      $items.css('width', itemsWidth + "px");
    });


    /**
    ** Global updates
    ****************************************/

    $win.on('resize', function(event) {
      itemSize      = { width: $item.first().outerWidth(),
                        height: $item.first().outerHeight() };
      itemsWidth    = lineBreak * itemSize.width;

      $items.css('width', itemsWidth + "px");
    });


    /**
    ** NO TOUCH EVENTS
    ************************************************************/

    if(!Modernizr.touchevents) {


      /**
      ** Variables
      ****************************************/

      var winWidth      = $win.outerWidth(),
          profilerWidth = $profiler.outerWidth(),
          itemSpace     = 60,
          pointer       = { x: 0, percent: 0 },
          ratio         = 0,
          range         = 0,
          gap           = 0,
          tmp           = 0,
          speed         = 20;


      /**
      ** Init
      ****************************************/

      $win.on('load', function() {
        ratio = (itemSpace * lineBreak) / itemsWidth;
        range = itemsWidth - profilerWidth;


        // Set each item space
        var counter = 0;

        $item.each(function(index) {
          var $this = $(this);

          if (counter == lineBreak) {
            counter = 0;
          }

          $this.css('transform', 'translateY(-' + (itemSpace * counter) + 'px)');
          counter++;
        });


        // Init default position
        gap = -range * 0.5;
        $items.css('transform', 'translate3d(' + gap + 'px, ' + (-gap * ratio) + 'px, 0px)');
      });


      /**
      ** Variables updates
      ****************************************/

      $win.on('resize', function(event) {
        winWidth      = $win.outerWidth();
        profilerWidth = $profiler.outerWidth();
        ratio         = (itemSpace * lineBreak) / itemsWidth;
        range         = itemsWidth - profilerWidth;

        // Reinit default position
        gap = -range * 0.5;
        $items.css('transform', 'translate3d(' + gap + 'px, ' + (-gap * ratio) + 'px, 0px)');
        $prev.removeClass('is-disabled');
        $next.removeClass('is-disabled');
      });


      /**
      ** Mousemove
      ****************************************/

      // $win.on('mousemove', function(event) {
      //   if($section.isInViewport()) {
      //     pointer.x = event.pageX;
      //     pointer.percent = pointer.x / winWidth;
      //     gap = -range * pointer.percent;
      //   }
      // });


      /**
      ** Prev
      ****************************************/

      $prev.on('click', function(event) {
        if(!$prev.hasClass('is-disabled')) {
          gap = gap + range * 0.25;
          if(gap >= 0) {
            $prev.addClass('is-disabled');
          }
          if($next.hasClass('is-disabled')) {
            $next.removeClass('is-disabled');
          }
        }
      });


      /**
      ** Next
      ****************************************/

      $next.on('click', function(event) {
        if(!$next.hasClass('is-disabled')) {
          gap = gap - range * 0.25;
          if(gap <= (-itemsWidth + profilerWidth)) {
            $next.addClass('is-disabled');
          }
          if($prev.hasClass('is-disabled')) {
            $prev.removeClass('is-disabled');
          }
        }
      });


      /**
      ** Loop
      ****************************************/

      var loop = function() {

        if(tmp != gap) {
          tmp = tmp + (gap - tmp) / speed;
          $items.css('transform', 'translate3d(' + tmp + 'px, ' + (-tmp * ratio) + 'px, 0px)');
        }

        window.requestAnimationFrame(loop);
      };


      /**
      ** Request
      ****************************************/

      window.requestAnimationFrame(loop);

    }

  }

})();
