/**
** JAVASCRIPT
** Name: Home
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  var $win      = $(window),
      $body     = $('body'),
      $home     = $('.js-home'),
      $intro    = $('.js-home-introduction'),
      $trigger  = $('.js-home-trigger'),
      $scroll   = $('.js-home-scroll'),
      $content  = $('.js-home-content'),
      scrollTop = 0;


  /**
  ** FUNCTION
  ** Animate introduction
  ****************************************/

  function animateIntroduction() {
    $intro.addClass('animate-in');

    setTimeout(function() {
      $intro.removeClass('animate-in').addClass('animate-out');
    }, 1200);
  }


  /**
  ** Init
  ****************************************/

  $win.on('load', function() {
    animateIntroduction();
  });


  /**
  ** Update
  ****************************************/

  $win.on('resize', function() {
    scrollTop = $win.scrollTop();
  });


  /**
  ** Click
  ****************************************/

  $trigger.on('click', function() {
    $intro.toggleClass('is-reversed');
  });


  $scroll.on('click', function(event) {
    event.preventDefault();

    $('html, body').stop().animate({
      scrollTop: $content.offset().top
    }, 800, 'swing');
  });


})();
