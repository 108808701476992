// import { throttle, debounce } from 'throttle-debounce';

import AOS from 'aos';

var hasAnimations = true;

if (window.matchMedia('(prefers-reduced-motion: reduce)').matches === true) {
  hasAnimations = false;
} else {
  document.documentElement.classList.add('has-animations');
}

$(window).on('load', function() {
  $('body').addClass('is-loaded');

  if (hasAnimations) {
    AOS.init({
      duration: 800,
    });
  }

});

// import "./front/components/_components.navigation"
import "./front/_showcase"
import "./front/_book"
import "./front/_card"
import "./front/_footer"
import "./front/_form"
import "./front/_header"
import "./front/_history"
import "./front/_home"
import "./front/_navigation"
import "./front/_prllx"
import "./front/_portrait"
import "./front/_profiler"
import "./front/_project"
import "./front/_slider"
import "./front/_slowmotion"
import "./front/_sticky"
