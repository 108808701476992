/**
** JAVASCRIPT
** Name: Project
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  var $win         = $(window),
      $project     = $('.js-project'),
      $head        = $('.js-project-head'),
      $background  = $('.js-project-background'),
      $steps       = $('.js-project-step'),
      $activeStep  = $steps.first(),
      steps        = [],
      winHeight    = $win.outerHeight(),
      scrollTop    = $win.scrollTop(),
      globalFactor = 0.5,
      opacity      = 1,
      offset       = 120;


  /**
  ** Functions
  ****************************************/

  function setActiveStep() {

    $.each(steps, function(index, step) {
      
      if(scrollTop >= (step.start - offset) && scrollTop < (step.end - offset)) {
        var $currentStep = $('.js-project-step[href="' + step.id + '"]');
        
        $steps.not($currentStep).removeClass('is-active');
        $currentStep.addClass('is-active');
      }
    });
  }


  /**
  ** Init
  ****************************************/

  $win.on('load', function() {

    $steps.each(function() {
      var $this   = $(this),
          id      = $this.attr('href'),
          $target = $(id),
          start   = $target.offset().top,
          end     = start + $target.outerHeight();

      steps.push({
        id: id,
        start: start,
        end: end
      });
    });

    setActiveStep();
  });


  /**
  ** Variables updates
  ****************************************/

  $win.on('resize', function(event) {
    winHeight = $win.outerHeight();
  });

  $win.on('scroll', function(event) {
    scrollTop = $win.scrollTop();
    setActiveStep();
  });


  /**
  ** Loop
  ****************************************/

  var loop = function() {
    var opacity = 1;

    if(scrollTop >= winHeight / 4) {
      var offset = winHeight / 4,
          start  = scrollTop - offset,
          end    = $head.outerHeight() - offset * 2.5;

      opacity = 1 - (start / end);
    }

    $background.css('opacity', Math.max(0, opacity));

    window.requestAnimationFrame(loop);
  };

  window.requestAnimationFrame(loop);

})();
