/**
** JAVASCRIPT
** Name: Prllx
********************************************************************************/

if (window.matchMedia('(prefers-reduced-motion: reduce)').matches === false){
  (function() {
    'use strict';


    /**
     ** Variables
     ****************************************/

    var $win         = $(window),
      $items       = $('.js-prllx'),
      winHeight    = $win.outerHeight(),
      scrollTop    = $win.scrollTop(),
      view         = scrollTop + winHeight / 2,
      globalFactor = 0.2;


    /**
     ** Variables updates
     ****************************************/

    $win.on('resize', function(event) {
      winHeight = $win.outerHeight();
      view      = scrollTop + winHeight / 2;
    });

    $win.on('scroll', function(event) {
      scrollTop = $win.scrollTop();
      view      = scrollTop + winHeight / 2;
    });


    /**
     ** Actions
     ****************************************/

    $win.on('load', function() {

      $items.each(function() {
        var $this = $(this);


        /**
         ** Loop
         ****************************************/

        var loop = function() {
          var factor = $this.data('prllx-factor') ? $this.data('prllx-factor') : globalFactor,
            fade   = $this.data('prllx-fade') ? true : false;

          if($this.data('prllx-anchor') && $this.data('prllx-anchor') == "window") {
            var $anchor = $win,
              offset  = winHeight / 2;
          }
          else {
            var $anchor = $this.data('prllx-anchor') ? $this.parents($this.data('prllx-anchor')) : $this,
              offset  = $anchor.offset().top + $anchor.outerHeight() / 2;
          }

          $this.css('transform', 'translate3d(0, ' + ((offset - view) * factor) + 'px, 0)');

          if(fade) {
            var opacity = 1;

            if(offset - view < 0) {
              opacity = 1 + (offset - view) / winHeight;
            }

            $this.css('opacity', Math.max(0, opacity));
          }

          window.requestAnimationFrame(loop);
        };

        window.requestAnimationFrame(loop);
      });

    });

  })();
}
