/**
** JAVASCRIPT
** Name: Footer
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  var $win         = $(window),
      $body        = $('body'),
      $title       = $('.js-footer-title'),
      $items       = $('.js-footer-items');


  /**
  ** Action
  ****************************************/

  $title.on('click', function() {
    var $this = $(this),
        $menu = $this.next('.js-footer-items');

    $title.not($this).removeClass('is-active');
    $this.toggleClass('is-active');
    $items.not($menu).removeClass('is-active');
    $menu.toggleClass('is-active');
  });

})();
