/**
** JAVASCRIPT
** Name: History
********************************************************************************/

(function() {
  'use strict';


  if($('.js-history').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win     = $(window),
        $body    = $('body'),
        $history = $('.js-history'),
        $aside   = $('.js-history-aside'),
        $filters = $('.js-history-filters'),
        start    = 0,
        end      = 0;


    /**
    ** Function: setPosition
    ****************************************/

    function setPosition(scrollTop) {

      if(scrollTop > end) {
        $filters.removeClass('is-top').addClass('is-bottom');
      }
      else if(scrollTop > start) {
        $filters.removeClass('is-bottom').addClass('is-top');
      }
      else {
        $filters.removeClass('is-top is-bottom');
      }
    };


    /**
    ** Function: updateVariables
    ****************************************/

    function updateVariables() {
      start = $aside.offset().top;
      end   = start + $aside.outerHeight() - $filters.outerHeight() - 26;
    };


    /**
    ** Init
    ****************************************/

    $win.on('load', function() {
      updateVariables();
      setPosition($win.scrollTop());
    });


    /**
    ** Resize
    ****************************************/

    $win.on('resize', function() {
      updateVariables();
      setPosition($win.scrollTop());
    });


    /**
    ** Action
    ****************************************/

    $win.on('scroll', function() {
      setPosition($win.scrollTop());
    });


  }

})();
