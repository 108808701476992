/**
** JAVASCRIPT
** Name: Book
********************************************************************************/

(function() {
  'use strict';


  if($('.js-book').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win    = $(window),
        $body   = $('body'),
        $circle = $('.js-book-circle'),
        height  = 0;


    /**
    ** Action
    ****************************************/

    $win.on('load', function() {
      height = $circle.outerHeight();
      $circle.css('width', height + 'px');
    });

    $win.on('resize', function() {
      height = $circle.outerHeight();
      $circle.css('width', height + 'px');
    });

  }

})();
