document.addEventListener('DOMContentLoaded', () => {

  const $captchas = document.querySelectorAll('.g-recaptcha');
  $captchas.forEach((element) => {
    const $form = element.closest('form');
    const $submit = $form.querySelector('[type="submit"]');
    const callbackId = $(element).data('callback');

    window[callbackId] = function (response) {
      $submit.setAttribute('aria-disabled', "true");
      $submit.setAttribute('disabled', "");
      $form.submit();
    };
  });
});
